.modal-inner {
    display: flex;
    max-width: 400px;
    flex-flow: row wrap;
    padding: 1.25rem;

    h1 {
        font-size: 1.25rem;
        flex: 1 1 100%;
        color: $main_color;
        text-align: center;
        margin-bottom: 1rem;
    }

    h2 {
        font-size: 1rem;
        flex: 1 1 100%;
        text-align: center;
        margin-bottom: 1rem;
    }

    .buttons {
        display: flex;
        flex-flow: row wrap;
        flex: 1 1 100%;
        gap: .5rem .5rem;

        .mat-raised-button {
            flex: 1 1;

            &.cancel {
                order: 1;
            }

            &.confirm {
                order: 2;
            }
        }
    }
}
