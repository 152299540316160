.section {
    padding: 2rem 0;
    color: $main_color;

    @media(max-width: 767px) {
        padding: 1rem 0;
    }

    &.bg-white {
        background: white;
    }

    &.bg-light {
        background: $gray_color;
    }

    h2 {
        font-size: 1.25rem;
        margin-bottom: .25rem;
    }

    h3 {
        font-size: 1rem;
        margin-bottom: .25rem;
    }

    td, th {
        font-size: .75rem;
        padding-right: 1rem;

        @media screen and (min-width: 576px) {
            font-size: 1rem;
            padding-right: 2rem;
        }
    }
}
