.chip {
    background: $main_color;
    color: white;
    font-weight: 700;
    font-size: .75rem;
    line-height: 1;
    text-transform: uppercase;
    padding: .375rem .5rem;

    i {
        font-size: .75rem;
        margin-right: .25rem;
    }

    &.clickable {
        cursor: pointer;

        &:hover {
            opacity: .75;
        }
    }

    &.transparent {
        background: none;
        color: $main_color; 

        i {
            color: $main_color; 
        }
    }
}