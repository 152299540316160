$main_color: #2F3841;
$sec_color: #414E5A;
$primary: #2AE7AC;
$week_indicator_border: $primary;
$gray_color: #F5F8FA;
$gray_color_mid: darken($gray_color, 10);
$gray_color_dark: darken($gray_color, 20);
$gray_color_darker: #767676;
$night_color: $sec_color;
$blue_stats_color: #C2BFD8;
$unavailable_color: #E6E6E6;
$unavailable_color_dark: #8F8F8F;
$green_color: $primary;
$blue_color: #67c6dd;
$green_sideborder_color: darken($green_color, 10);
$green_darker_color: #16C890;
$green_darker_sidebar_color: #297635;
$red_color: #FF0000;
$red_sidebar_color: #B10000;
$border_color: $main_color;
$border_blue_color: $gray_color_dark;
$highlight: #f6f5f9;

$error_color: #F44336;

$blue: $main_color !default;
$indigo: #6610F2 !default;
$purple: #6F42C1 !default;
$pink: #E83E8C !default;
$red: #DC3545 !default;
$orange: #FD7E14 !default;
$yellow: #FFC107 !default;
$green: #28A745 !default;
$teal: #20C997 !default;
$cyan: #17A2B8 !default;
$warning_bg: #fff7e6;


$zindex_header: 1000;
$zindex_sticky: 10;
$zindex_unavailable: 8;
$zindex_planning: 9;
$zindex_planning_hover: 10;
$zindex_selector: 7;
$zindex_logo_login: 0;

$header_height: 60px;
$tons_height: 60px;
$hourWith: 10px;
$entityHeight: 65px;
$entityHeightBig: 104px;
$dateBarHeight: 40px;

$shadow-button: 0 2px 5px rgba(0, 0, 0, 0.2);
$shadow-normal: 0 5px 10px rgba(0, 0, 0, 0.2);
$shadow-big: 0 5px 30px rgba(0, 0, 0, 0.3);

$header-logo: url("/assets/images/logo-codalt-small.svg");
$icon-append: "";

.brand .logo {
    background-position: center;
    background-size: auto 80% !important;
}

.login-background:before {
    display: none !important;
}

@mixin sideborder($color) {
    position: relative;
    &:before {
        content: ' ';
        width: 7px;
        background-color: $color;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
    }
}

@mixin warningBefore() {
    &:before {
        font-family: 'Font Awesome 6 Pro';
        font-weight: 900;
        content: '\f071';
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        padding-right: 10px;
    }
}

