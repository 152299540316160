.mat-raised-button.planning-button {
    line-height: 2.5rem;
    padding: 0 2rem !important;

    @media(max-width: 767px) {
        padding: 0 .5rem !important;
    }

    &.cancel {
        color: $main_color;
        border: solid 1px rgba($main_color, .25)
    }
}
