@use '@angular/material' as mat;

@mixin tr-style {
    th {
        background-color: $main_color;
        color: #ffffff;
        font-weight: normal;
        border: 1px solid $main_color;
        padding: 0.50rem 0.75rem;
    }

    td {
        textarea {
            width: 100%;
            border: 0;
            padding: 0;

            &:focus-visible {
                outline: 0;
            }
        }


        &:not(.text-input),
        &:not(.cutter-selection),
        &:not(.number-input),
        &:not(.wiper-selection),
        &:not(.tabletime) {
            padding: 0.50rem 0.5rem;
        }

        &.textarea-input {
            padding: 0.5rem 0;

            textarea {
                padding: 0 0.5rem;
                width: calc(100% - 1rem);

                &::-webkit-scrollbar {
                    width: 0.5rem;
                }

                &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }

                &::-webkit-scrollbar-thumb {
                    background: #888;
                    cursor: default;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background: $main_color;
                    cursor: default;
                }
            }
        }

        &.datetime-input {
            input {
                padding: 0;
                border-bottom-width: 0px !important;
            }
        }

        &.text-input, &.cutter-selection, &.number-input, &.wiper-selection, &.tabletime {
            padding: 0;

            .mat-select, input {
                padding: 0.5rem 0;
                margin: 0px !important;
            }
        }

        color: $main_color;
        border-right: 1px solid $main_color;

        &:first-child {
            border-left: 1px solid $main_color;
        }

        border-bottom: 1px solid $main_color;
    }
    td.drag {
        position: relative;

        .drag-handle {
            position: absolute;
            top: 0;
            left: -24px;
            padding: 9px 30px 9px 0;
            opacity: 0;
            transition: all 250ms;
            cursor: move;
        }
    }

    &:hover {
        td.drag {
            .drag-handle {
                opacity: 1;
            }
        }
    }
}

tr.cdk-drag-preview {
    @include tr-style;
    box-sizing: border-box;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);

    td {
        opacity: 0.5;
        border: 1px solid transparent;
        display: table-cell;
        color: transparent;

        &.drag {
            .drag-handle {
                opacity: 1 !important;
                color: $main_color;
            }
        }
    }

    display: table-row;
}

tr.cdk-drag-placeholder {
    opacity: 1;

    td {
        background-color: $gray_color
    }
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}


.table {
    tr {
        @include tr-style;

    }

    &.table-hover {
        tr:hover {
            background-color: transparent;

            td {
                background-color: $gray_color;

                input, textarea {
                    background-color: $gray_color
                }
            }
        }
    }

    .mat-icon-button {
        margin: -8px -3px -8px -12px;
    }

    &.table-add-row {
        tr {
            &:last-child {
                td {
                    padding: 0.10rem 0;
                    color: darken($gray_color, 8);
                    border-right: 1px solid $gray_color;
                    border-bottom: 1px solid $gray_color;

                    &:first-child {
                        border-left: 1px solid $gray_color;
                    }
                }
            }
        }
    }


    .form-submitted {
        &.ng-invalid {
            td {
                background-color: mat.get-color-from-palette($PlanningDvdS-warn, default, 0.4);
            }
        }
    }

    &.table-last-col {
        tr {

            &:hover {
                &:last-child {
                    tr {
                        background-color: transparent;
                    }
                }
            }

            td, th {
                &:last-child {
                    width: 50px;

                    border: 1px solid transparent;
                    background-color: transparent;
                }
            }

            &.ng-invalid {
                td {
                    &:last-child {
                        background-color: transparent;
                    }
                }
            }
        }

        &.table-add-row {
            tr {
                &:last-child {
                    td {
                        &:last-child {
                            border: 1px solid transparent;
                        }
                    }
                }
            }
        }
    }

    &.table-center {
        th, td {
            text-align: center;
        }
    }

    input, .tabletime, .mat-select, app-datetime-input {
        &:focus-visible {
            outline: 0;
        }

        &.ng-touched {
            &.ng-invalid {
                background-color: $error_color;
                color: #fff;

                input {
                    background-color: $error_color;
                    color: #fff;
                }
            }
        }
    }

    td:has(input.ng-invalid), td:has(app-datetime-input.ng-invalid) {
        background-color: $error_color;
    }


    .number-input {
        width: 90px;

        input {
            width: 100%;
        }
    }

    .comment {
        width: 390px;
    }

    .datetime {
        width: 240px;
    }

    .tabledatepicker {
        > div {
            display: flex;
        }
    }

    .tabledate {
        width: 73px;
    }

    .tabletime {
        width: 130px;

        &.one {
            width: 61px;
        }

        .timepicker {
            display: flex;

            > span {
                line-height: 38px;
            }

            > .mat-error {
                display: none;
            }

            &.ng-invalid {
                > .mat-error {
                    display: block;
                }
            }

            > .mat-select {
                padding-bottom: 9px;

                .mat-select-trigger {
                    display: flex;
                    flex-direction: column;
                    height: 25px;
                    margin-bottom: -2px;

                    .mat-select-value {
                        display: block;
                        max-width: 100px;
                        height: 21px;
                        max-height: 23px;
                    }

                    .mat-select-arrow-wrapper {
                        display: flex;
                        justify-content: center;
                        opacity: 0;
                        transition: opacity 200ms;
                        height: auto;
                    }
                }

                &:focus {
                    .mat-select-trigger {
                        .mat-select-arrow-wrapper {
                            opacity: 1;
                        }
                    }
                }
            }

            &:hover {
                > .mat-select {
                    .mat-select-trigger {
                        .mat-select-arrow-wrapper {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
