// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$bv-brand: (
    50: #ffffff,
    100: lighten($main_color, 40),
    200: lighten($main_color, 30),
    300: lighten($main_color, 15),
    400: $main_color,
    500: $main_color,
    600: $main_color,
    700: $main_color,
    800: darken($main_color, 5),
    900: darken($main_color, 10),
    A100: #ffffff,
    A200: $main_color,
    A400: $main_color,
    A700: $main_color,
    contrast: (
        50: $black-87-opacity,
        100: $black-87-opacity,
        200: $black-87-opacity,
        300: $black-87-opacity,
        400: $black-87-opacity,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: $black-87-opacity,
        A200: $black-87-opacity,
        A400: $black-87-opacity,
        A700: white,
    )
);
$bv-brand-accent: (
    50: #ffffff,
    100: lighten($sec_color, 40),
    200: lighten($sec_color, 30),
    300: lighten($sec_color, 15),
    400: $sec_color,
    500: lighten($sec_color, 10),
    600: lighten($sec_color, 5),
    700: $sec_color,
    800: darken($sec_color, 5),
    900: darken($sec_color, 10),
    A100: $sec_color,
    A200: $sec_color,
    A400: $sec_color,
    A700: $sec_color,
    contrast: (
        50: $black-87-opacity,
        100: $black-87-opacity,
        200: $black-87-opacity,
        300: $black-87-opacity,
        400: $black-87-opacity,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: $black-87-opacity,
        A200: $black-87-opacity,
        A400: $black-87-opacity,
        A700: white,
    )
);


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$PlanningDvdS-primary: mat.define-palette($bv-brand);
$PlanningDvdS-accent: mat.define-palette($bv-brand-accent, A700, A100, A400);

// The warn palette is optional (defaults to red).
$PlanningDvdS-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$PlanningDvdS-theme: mat.define-light-theme($PlanningDvdS-primary, $PlanningDvdS-accent, $PlanningDvdS-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($PlanningDvdS-theme);

/* You can add global styles to this file, and also import other style files */

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-form-field-appearance-fill {
    .mat-form-field-flex {
        background-color: $gray_color !important;
    }
}

.mat-form-field-subscript-wrapper {
    top: calc(100% - 2.2em);
}

.mat-optgroup-label {
    margin: 0 !important;
}

.cdk-global-scrollblock {
    overflow-y: auto;
}
